import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

const MenuIT = () => {
  return (
    <Layout>
      <SEO title="Meni Konoba Fakin" lang="it" />
      <div className="content menu-page">
        <div className="naslov-container">
          <h1>Menù</h1>
          <div>
            <Link to="/menu">HR</Link>
            <Link to="/menu-en">EN</Link>
            <Link to="/menu-de">DE</Link>
            <Link to="/menu-fr">FR</Link>
            <Link to="/menu-rus">RUS</Link>
          </div>
        </div>
        <h3>Antipasti freddi</h3>
        <ul>
          <li>Crema di tartufo con formaggio al tartufo</li>
          <li>
            Insalata con gamberetti, formaggio di capra e cetrioli freschi
          </li>
          <li>Tartare di filetto di manzo con aïoli e bruschetta</li>
          <li>Vitello tonnato</li>
          <li>Paté di fegato con tartufi e salsiccia istriana</li>
          <li>Prosciutto crudo nostrano con olive</li>
          <li>
            Piatto Fakin per 2 persone (Prosciutto crudo, salsicce, formaggio
            nostrani e formaggio tartufato)
          </li>
        </ul>
        <h3>Brodi</h3>
        <ul>
          <li>Zuppa al tartufo nero</li>
          <li>Vellutata di asparagi</li>
          <li>Minestra istriana</li>
        </ul>
        <h3>Antipasti caldi</h3>
        <ul>
          <li>Casarecce al tartufo nero</li>
          <li>Frittata al tartufo nero</li>
          <li>Spaghetti con gamberetti, cozze e pomodori ciliegini</li>
          <li>Paccheri alla Bolognese con rafano fresco</li>
          <li>
            Gnocco alla Romana al ragù di tartufo e con chips di pancetta
            istriana
          </li>
          <li>Gulasch di manzo con gnocchi fatti in casa</li>
        </ul>
        <h3>Secondi</h3>
        <ul>
          <li>
            Filetto di branzino gratinato alle erbe aromatiche, con ratatouille
            e purè di patate all'erba cipollina
          </li>
          <li>
            Filetto di pollo ripieno di formaggio e cotto in forno su spinaci e
            champignons
          </li>
          <li>
            Costoletta di agnello al ragù di timo, patate al forno con rosmarino
            e verdure alla julienne
          </li>
          <li>Bistecca al tartufo nero su purè di patate</li>
          <li>
            Bistecca al ragù di Terrano (vino) con purè di sedano e insalata
            mista
          </li>
          <li>
            Filetto di maiale nostrano al tartufo nero con patate al forno
          </li>
          <li>Verdure alla griglia</li>
          <li>Ragù alla selvaggina con polenta al forno</li>
        </ul>
        <h3>Dolci</h3>
        <ul>
          <li>Soufflé al cioccolato</li>
          <li>Tartufo al cioccolato</li>
          <li>Praline di cioccolato con tartufi</li>
          <li>Crespelle con crema all'arancia e Cointreau</li>
          <li>Crème brûlée</li>
          <li>
            Piatto di formaggi istriani con olio d'oliva, miele e mandorle
          </li>
        </ul>
        <h3>Insalate</h3>
        <ul>
          <li>Insalata con tartufi</li>
          <li>Scelta giornaliera di insalate stagionali</li>
        </ul>
      </div>
    </Layout>
  )
}

export default MenuIT
